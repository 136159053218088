import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'buy',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'rental-request',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/rent/rent.module').then((m) => m.RentModule),
  },
  {
    path: 'goals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/goals/goals.module').then((m) => m.GoalsModule),
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'refer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/referral/referral.module').then((m) => m.ReferralModule),
  },
  {
    path: 'videos',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/videos/videos.module').then((m) => m.VideosModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
