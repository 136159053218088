import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[horizontalScrollShadow]',
})
export class HorizontalScrollShadowDirective
  implements AfterViewInit, OnDestroy
{
  private scrollContainer!: HTMLElement;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.scrollContainer = this.getScrollableChild(this.el.nativeElement);

    if (!this.scrollContainer) {
      console.error('No scrollable child found inside', this.el.nativeElement);
      return;
    }

    this.updateShadows();
    this.scrollContainer.addEventListener('scroll', this.updateShadows);
    window.addEventListener('resize', this.updateShadows);
  }

  private getScrollableChild(parent: HTMLElement): HTMLElement {
    for (const child of Array.from(parent.children)) {
      if (child.scrollWidth > child.clientWidth) {
        return child as HTMLElement;
      }
    }
    return parent;
  }

  private readonly updateShadows = (): void => {
    const canScrollLeft = this.scrollContainer.scrollLeft > 0;
    const canScrollRight =
      this.scrollContainer.scrollWidth >
      this.scrollContainer.clientWidth + this.scrollContainer.scrollLeft;

    this.renderer[canScrollLeft ? 'addClass' : 'removeClass'](
      this.el.nativeElement,
      'has-left-shadow'
    );
    this.renderer[canScrollRight ? 'addClass' : 'removeClass'](
      this.el.nativeElement,
      'has-right-shadow'
    );
  };

  ngOnDestroy(): void {
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.updateShadows);
    }
    window.removeEventListener('resize', this.updateShadows);
  }
}
